import Cookie from 'js-cookie'
import router from '@/router'
import axios from 'axios'
import api from '@/api'
import BrainScan from '@/config'

export default {

	logoutUser({commit}, payload) {
		if (Cookie.get('authToken')) {
			Cookie.remove('authToken');
		}
		commit('authStatusChange', payload);
		router.push('/login')
	},

	loginUser({commit}, payload) {
		return axios.post(`${api.basicUrl}/token/`, {
			username: payload.username,
			password: payload.password
		})
			.then(res => {
				if (200 === res.status) {
					BrainScan.setToken(res.data.access);
					commit('authStatusChange', true);
					router.push({
						path: '/'
					});
				}
			})
			.catch(err => {
				return err
			})
	}
}
