<template>
	<TheIconComponent
		icon-style="plus"
		@click="addQuestion"
		:pointer="true"
	/>
</template>

<script>
import TheIconComponent from '@/components/basic/TheIconComponent';

export default {
	props: ['questionId'],
	name: "QuestionSingleAdd",
	components: {
		TheIconComponent
	},
	methods: {
		addQuestion() {
			this.$emit('questionAdd', this.questionId)
		}
	}
}
</script>