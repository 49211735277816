<template>
	<form v-if="formVisibility" autocomplete="off">
<!--		Etot shablon (ujas) sluchilsa izza neponyatok na backend v nachale proekta, dalshe peredelivat ne bilo vremeni weep-weep -____- -->
		<div class="container">
			<div class="row">
				<div class="col-6">
					<label for="category">
						<select
							name="category"
							id="category"
							v-model="formData.category"
							required="required"
						>
							<option
								v-for="singleCategory of categories"
								:key="singleCategory.id"
								:value="singleCategory.id"
							>
								{{ singleCategory.title }}
							</option>
						</select>
					</label>
					<label for="main_question">
						<input
							id="main_question"
							type="text"
							placeholder="Main Question"
							:class="!formData.title.trim() ? 'required' : ''"
							v-model="formData.title"
						>
					</label>
					<label
						:for="singleQuestion.recommendations[0].title"
					>
						<textarea
							:class="!singleQuestion.recommendations[0].recommendation_title.trim() ? 'required' : ''"
							:placeholder="singleQuestion.recommendations[0].title"
							:name="singleQuestion.recommendations[0].title"
							:id="singleQuestion.recommendations[0].title"
							v-model="singleQuestion.recommendations[0].recommendation_title"
						/>
					</label>
					<label
						:for="singleQuestion.recommendations[1].title"
					>
						<textarea
							:class="!singleQuestion.recommendations[1].recommendation_title.trim() ? 'required' : ''"
							:placeholder="singleQuestion.recommendations[1].title"
							:name="singleQuestion.recommendations[1].title"
							:id="singleQuestion.recommendations[1].title"
							v-model="singleQuestion.recommendations[1].recommendation_title"
						/>
					</label>
					<label
						:for="singleQuestion.recommendations[2].title"
					>
						<textarea
							:class="!singleQuestion.recommendations[2].recommendation_title.trim() ? 'required' : ''"
							:placeholder="singleQuestion.recommendations[2].title"
							:name="singleQuestion.recommendations[2].title"
							:id="singleQuestion.recommendations[2].title"
							v-model="singleQuestion.recommendations[2].recommendation_title"
						/>
					</label>


					<label
						:for="singleQuestion.information"
					>
						<textarea
							:class="!singleQuestion.information.trim() ? 'required' : ''"
							placeholder="Information"
							:name="singleQuestion.information"
							v-model="singleQuestion.information"
						/>
					</label>
				</div>

				<div class="col-6">
					<label
						for="subquestion"
					>
						<textarea
							:class="singleQuestion.subquestion[0].title && !singleQuestion.subquestion[0].title.trim() ? 'required' : ''"
							name="subquestion"
							id="subquestion"
							placeholder="Subquestion"
							v-model="singleQuestion.subquestion[0].title"
						/>
					</label>
					<label
						for="subquestion_recommendation"
					>
						<textarea
							v-if="singleQuestion.subquestion[0].title.trim()"
							:class="singleQuestion.subquestion[0].title && !singleQuestion.subquestion[0].recommendations[0].recommendation_title.trim() ? 'required' : ''"
							name="subquestion_recommendation"
							id="subquestion_recommendation"
							placeholder="Yes"
							v-model="singleQuestion.subquestion[0].recommendations[0].recommendation_title"
						/>
					</label>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
export default {
	props: {
		singleQuestion: {
			type: Object,
			required: true
		},
		categories: {
			type: Array,
			required: true
		},
		formVisibility: {
			type: Boolean,
			required: true
		}
	},
	name: "QuestionsSingleTable",
	data() {
		return {
			formData: this.singleQuestion,
		}
	},
	watch: {
		'singleQuestion.subquestion': {
			deep: true,
			handler(value) {
				if (!value[0].title) {
					this.formData.subquestion[0].recommendations[0].recommendation_title = '';
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 15px;
}

.row {
	margin: 0 -15px;
	display: flex;
	flex-wrap: wrap;
}

.col-6 {
	@media (min-width: 1200px) {
		flex: 0 50%;
		max-width: 50%;
	}
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding: 0 15px;
	flex: 0 1 100%;
	max-width: 100%;

	&.buttons-wrapper {
		flex-direction: row;
		@media (max-width: 767px) {
			justify-content: center;
			margin-bottom: 10px;
		}
	}

	&.align-end {
		justify-content: flex-end;
		@media (max-width: 767px) {
			justify-content: space-between;
		}
	}
}

form {
	margin-top: 20px;

	input,
	textarea {
		&.required {
			border-bottom-color: red;
		}
	}

	input,
	select,
	textarea {
		box-sizing: border-box;
		display: block;
		padding: 15px;
		color: black;
		border-width: 0 0 1px 0;
		border-radius: 0;
		border-color: #ccc;
		background: transparent;
		width: 100%;
		margin: 0 auto 20px;

		&:focus {
			outline: none;
		}
	}

	textarea {
		max-width: 100%;
		min-width: 100%;
		min-height: 120px;
	}

	button,
	input[type="submit"] {
		box-sizing: border-box;
		padding: 15px;
		border: none;
		background-color: #92A8D1;
		color: white;
		border-radius: 25px;
		width: 150px;
		margin: 0 10px 0 0;
		cursor: pointer;
		transition: ease all .4s;
		@media (max-width: 767px) {
			margin: 0
		}

		&:focus {
			outline: none;
		}

		&:hover {
			color: white;
			background-color: #34558b;
		}

		&:last-child {
			margin: 0;
		}
	}
}

</style>