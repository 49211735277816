import Vue from 'vue'
import { ClientTable } from 'vue-tables-2'
import VueNotification from 'vue-notification'
import CKEditor from '@ckeditor/ckeditor5-vue2'

import App from '@/App.vue'
import store from '@/store'
import router from '@/router'

import('normalize.css')
import('@/assets/assets.scss')

Vue.use(CKEditor)
Vue.use(ClientTable)
Vue.use(VueNotification)

let app = '';

if (!app) {
    app = new Vue({
        store,
        router,
        render: h => h(App)
    }).$mount('#app')
}
