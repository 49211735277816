<template>
    <footer class="footer">
    </footer>
</template>

<script>
    export default {
        name: "dashboardFooter"
    }
</script>

<style scoped>

</style>
