import Vue from 'vue'
import api from '@/api'
import axios from 'axios'
import store from '@/store'
import Cookie from 'js-cookie'

class BrainScanAPI {
	constructor() {
		this.createInstance()
	}

	token = ''

	createInstance = () => {
		this.instance = axios.create({
			baseURL: `${api.basicUrl}/`,
			headers: {
				'Authorization': this.token ? this.token : 'Bearer ' + Cookie.get('authToken')
			}
		})
	}

	setToken = token => {
		this.token = 'Bearer ' + token
		Cookie.set('authToken', token, {expires: 7})
		this.createInstance()
	}

	getCustomers = async params => {
		try {
			return await this.instance.get('/customers/', {
				params
			})
		} catch (e) {
			this.handleError(e)
		}
	}

	deleteUserById = async id => {
		try {
			const response = await this.instance.delete(`/customers/${id}/`)
			this.handleSuccess(response.data.detail)
			return response
		} catch (e) {
			this.handleError(e)
		}
	}

	getQuestions = async () => {
		try {
			return await this.instance.get('/questions/')
		} catch (e) {
			this.handleError(e)
		}
	}

	getVideos = async () => {
		try {
			return await this.instance.get('/questions/videos/')
		} catch (e) {
			this.handleError(e)
		}
	}

	getVideosCategories = async () => {
		try {
			return await this.instance.get('/questions/videos/types/')
		} catch (e) {
			this.handleError(e)
		}
	}

	saveVideos = async videos => {
		try {
			const response = await this.instance.post('/questions/videos/', videos)
			this.handleSuccess(response.data)
			return response
		} catch (e) {
			this.handleError(e)
		}
	}

	deleteVideoById = async id => {
		try {
			const response = await this.instance.delete(`/questions/videos/${id}/`)
			Vue.notify({
				group: 'success',
				type: 'success',
				title: response.data.detail
			})
		} catch (e) {
			this.handleError(e)
		}
	}

	getCategories = async () => {
		try {
			const response = await this.instance.get('/questions/categories/')
			return response.data
		} catch (e) {
			this.handleError(e)
			return {error: true}
		}
	}

	saveQuestions = async data => {
		/* eslint-disable no-unused-vars */
		const transformedData =
			data.map((item, index) => {
				const {
					id,
					...rest
				} = item;
				rest.order_number = index + 1
				return rest
			}).map(item => {
				if (!item.subquestion[0].title) {
					delete item.subquestion
				}
				return item
			})
		/* eslint-enable no-unused-vars */
		try {
			const response = await this.instance.post('/questions/', transformedData)
			this.handleSuccess(response.data)
			return response
		} catch (e) {
			this.handleError(e)
		}
	}

	getQuestionsCategories = async () => {
		try {
			return await this.instance.get('/questions/categories/')
		} catch (e) {
			this.handleError(e)
		}
	}

	saveQuestionCategories = async data => {
		try {
			const response = await this.instance.patch('/questions/categories/', data)
			this.handleSuccess('Categories successfully updated')
			return response
		} catch (e) {
			this.handleError(e)
		}
	}

	handleError = e => {
		if (e.response && 401 === e.response.status) {
			store.dispatch('logoutUser');
			Vue.notify({
				group: 'error',
				type: 'error',
				title: 'Error occurred',
				text: `You are not allowed here`
			})
		} else {
			Vue.notify({
				group: 'error',
				type: 'error',
				title: 'Error occurred',
				text: e
			})
		}
		// e?.response?.data && router.push({ path: '/' })
	}

	handleSuccess = e => {
		Vue.notify({
			group: 'success',
			type: 'success',
			title: e
		})
	}
}

const BrainScanAPIInstance = new BrainScanAPI();

export default BrainScanAPIInstance

