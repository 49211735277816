<template>
  <Content />
</template>

<script>
import Content from '@/components/Content'

export default {
  name: "ContentPage",
  components: {
    Content
  }
}
</script>

<style scoped>

</style>