<template>
  <div class="wrapper">
    <form @submit.prevent="handleVideoSubmit">
      <VideosSingle
          :new-item-mode="false"
          :categories="categories"
          :single-video="singleItem"
      />
      <div class="wrapper-video-buttons">
        <router-link class="nav-item" to="/videos">
          <button class="video-button">Cancel</button>
        </router-link>
        <button class="video-button">Add new video</button>
      </div>
    </form>
    <div class="preloader-wrapper" v-if="uploadStatus">
      <div class="gooey">
        <span class="dot"></span>
        <div class="dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import BrainScanAPIInstance from '@/config'
import VideosSingle from '@/components/Videos/VideosSingle'

export default {
  name: "VideosSingleNewItem",
  components: {
    VideosSingle
  },
  data() {
    return {
      uploadStatus: false,
      categories: [],
      singleItem: {
        title: '',
        url: '',
        type: ''
      }
    }
  },
  mounted() {
    this.fetchVideosCategories()
  },
  methods: {
    async fetchVideosCategories() {
      const categories = await BrainScanAPIInstance.getVideosCategories()
      this.categories = categories.data
    },
    async handleVideoSubmit() {
      if (!this.singleItem.url) {
        Vue.notify({
          group: 'error',
          type: 'error',
          title: 'Error occurred',
          text: 'Please select video to upload'
        })
      } else {
        this.uploadStatus = true
        const dataToSend = new FormData();
        dataToSend.append(`title`,this.singleItem.title)
        dataToSend.append(`url`,this.singleItem.url)
        dataToSend.append(`type`,this.singleItem.type)
        await BrainScanAPIInstance.saveVideos(dataToSend)
        this.uploadStatus = false
        this.$router.push({ path: '/videos/' })
      }
    }
  }
}
</script>

<style scoped lang="scss">

.wrapper {
  position: relative;
  padding: 40px;

  &-video-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .video-button {
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 25px;
    padding: 15px 25px;
    border: 1px solid #eeeeee;
    transition: .4s ease all;
    margin-top: 20px;
    margin-left: 15px;

    &:hover {
      background-color: #92A8D1;
    }

    &:focus {
      outline: none;
    }
  }
}

.gooey {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 142px;
  height: 40px;
  margin: -20px 0 0 -71px;
  background: #fff;
  filter: contrast(20);
}
.gooey .dot {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 15px;
  filter: blur(4px);
  background: #000;
  border-radius: 50%;
  transform: translateX(0);
  animation: dot 2.8s infinite;
}
.gooey .dots {
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  animation: dots 2.8s infinite;
}
.gooey .dots span {
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  filter: blur(4px);
  background: #000;
  border-radius: 50%;
}
@-moz-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@-webkit-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@-o-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@-moz-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}
@-webkit-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}
@-o-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}
@keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}

.preloader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffbf;
}

</style>
