<template>
  <div class="single-question-category-wrapper">
    <div class="single-question-category-wrapper-top">
      <span class="single-question-category-title" @click.prevent="toggleVisibility">{{
          singleQuestionCategory.title
        }}</span>
      <TheIconComponent
          :pointer="true"
          icon-style="expand"
          @click="toggleVisibility"
          class="expand-controller"
          :style="{
					transform: visibility ? 'rotate(180deg)' : 'rotate(0deg)',
					transition: '.4s ease all'
				}"
      />
    </div>
    <ckeditor
        v-if="visibility"
        :editor="editor"
        v-model="singleQuestionCategory.description"
        :config="editorConfig"
    />
  </div>
</template>

<script>
import TheIconComponent from '@/components/basic/TheIconComponent'

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'

import List from '@ckeditor/ckeditor5-list/src/list.js'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'

export default {
  props: {
    singleQuestionCategory: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      visibility: false,
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          Underline,
          Alignment,
          BoldPlugin,
          Heading,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          BlockQuote,
          ListStyle,
          List,
          Indent
        ],
        toolbar: {
          items: [
            'heading', '|',
            'bold', 'italic', 'underline', 'link', '|',
            'blockQuote', 'undo', 'redo', '|',
            'bulletedList', 'numberedList', '|',
            'outdent', 'indent', '|'
          ]
        }
      }
    }
  },
  components: {
    TheIconComponent
  },
  methods: {
    toggleVisibility() {
      this.visibility = !this.visibility
    }
  },
  name: "SingleQuestionCategory"
}
</script>

<style scoped lang="scss">

.single-question-category {

  &-title {
    display: block;
    font-size: 20px;
    cursor: pointer;
  }

  &-wrapper {

    margin: 10px 10px 35px;
    padding: 10px;
    border: 1px solid #eeeeee;
    border-radius: 5px;

    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
    }
  }
}

</style>