import Vue from 'vue'
import store from '@/store'
import Login from '@/views/Login'
import VueRouter from 'vue-router'

import Videos from '@/views/Videos'
import MainView from '@/views/MainView'
import ContentPage from '@/views/ContentPage'
import Dashboard from '../views/Dashboard.vue'
import CustomersPage from '@/views/CustomersPage'
import QuestionsPage from '@/views/QuestionsPage'
import VideosSinglePage from '@/views/VideosSinglePage'

Vue.use(VueRouter);

let routes = [
	{
		path: '*',
		redirect: '/'
	},
	{
		path: '/',
		component: Dashboard,
		children: [
			{
				path: '/',
				name: 'dashboard',
				component: MainView
			},
			{
				path: '/users',
				name: 'Users',
				component: CustomersPage
			},
			{
				path: '/questions',
				name: 'Questions',
				component: QuestionsPage
			},
			{
				path: '/videos',
				name: 'Videos',
				component: Videos
			},
			{
				path: '/videos/new',
				name: 'New Video',
				component: VideosSinglePage
			},
			{
				path: '/content',
				name: 'Content',
				component: ContentPage
			}
		],
		beforeEnter: (to, from, next) => {
			if (store.state.isAuth) {
				next();
			} else {
				next('/login');
			}
		}
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		beforeEnter: (to, from, next) => {
			if (store.state.isAuth) {
				next('/')
			} else {
				next();
			}
		}
	}
];

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes: routes
});


export default router
