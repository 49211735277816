<template>
  <VideosSingleNewItem />
</template>

<script>
import VideosSingleNewItem from '@/components/Videos/VideosSingleNewItem'

export default {
  name: "VideosSinglePage",
  components: {
    VideosSingleNewItem
  }
}
</script>
