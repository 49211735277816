<template>
  <VideosWrapper />
</template>

<script>
import VideosWrapper from '@/components/Videos/VideosWrapper'

export default {
  name: "Videos",
  components: {
    VideosWrapper
  }
}
</script>
