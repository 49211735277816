<template>
	<TheIconComponent
		icon-style="plus"
		@click="addSingleQuestion"
		:pointer="true"
		:height="35"
		:width="35"
	/>
</template>

<script>

import TheIconComponent from '@/components/basic/TheIconComponent'

export default {
	name: "QuestionsAdd",
	components: {
		TheIconComponent
	},
	data() {
		return {
			buttonStyles: {
				height: '30px',
				width: '30px'
			}
		}
	},
	methods: {
		addSingleQuestion() {
			this.$emit('addSingleQuestion')
		}
	}
}
</script>