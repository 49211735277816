import { render, staticRenderFns } from "./TheDashboardHeader.vue?vue&type=template&id=79272976&scoped=true&"
import script from "./TheDashboardHeader.vue?vue&type=script&lang=js&"
export * from "./TheDashboardHeader.vue?vue&type=script&lang=js&"
import style0 from "./TheDashboardHeader.vue?vue&type=style&index=0&id=79272976&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79272976",
  null
  
)

export default component.exports