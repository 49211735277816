<template>
	<div class="questions-wrapper">
		<QuestionsAdd
			v-if="!questions.length"
			@addSingleQuestion="addQuestion"
		/>
		<draggable
			v-model="questions"
			:animation="200"
			:disabled="draggableDisabled"
			handle=".draggable-handler"
		>
			<transition-group>
				<QuestionsSingle
					v-for="singleQuestion in questions"
					:key="singleQuestion.id"
					:question="singleQuestion"
					:categories="categories"
					@onQuestionAdd="addQuestion"
					@onQuestionDelete="deleteQuestion"
					@onSingleQuestionChange="onSingleQuestionChangeHandler"
				/>
			</transition-group>
		</draggable>
		<QuestionsSave
			@onQuestionsSave="onQuestionsSave"
		/>
		<DeleteConfirmationPopup
			:visibility="popupData.popupVisibility"
			:deleteQuestionId="popupData.deleteId"
			@on-question-delete="handleDeleteQuestionConfirmation"
			@on-popup-close="handlePopupClose"
		/>
	</div>
</template>

<script>
import draggable from 'vuedraggable'

import BrainScanAPIInstance from '@/config'
import QuestionsAdd from '@/components/Questions/QuestionsAdd'
import QuestionsSave from '@/components/Questions/QuestionsSave'
import QuestionsSingle from '@/components/Questions/QuestionsSingle'
import DeleteConfirmationPopup from '@/components/Questions/QuestionDeleteConfirmationPopup'

const recommendationTypes = [
	{
		answer_class_id: 4,
		recommendation_title: '',
		title: 'Yellow'
	},
	{
		answer_class_id: 5,
		recommendation_title: '',
		title: 'Orange'
	},
	{
		answer_class_id: 6,
		recommendation_title: '',
		title: 'Red'
	}
];

const mockNewItem = id => ({
	"id": id,
	"category": 1,
	"title": "New Question",
	"information": "",
	"recommendations": [
		{
			"answer_class_id": 2,
			"title": "Yellow",
			"recommendation_title": ""
		},
		{
			"answer_class_id": 3,
			"title": "Orange",
			"recommendation_title": ""
		},
		{
			"answer_class_id": 4,
			"title": "Red",
			"recommendation_title": ""
		}
	],
	"subquestion": [
		{
			"title": "",
			"recommendations": [
				{
					"answer_class_id": 5,
					"recommendation_title": "",
					"title": ""
				}
			]
		}
	]
})

export default {
	name: "Questions",
	components: {
		QuestionsSingle,
		QuestionsSave,
		QuestionsAdd,
		draggable,
		DeleteConfirmationPopup
	},
	data() {
		return {
			questions: [],
			categories: [],
			popupData: {
				popupVisibility: false,
				deleteId: null
			},
			draggableDisabled: false
		}
	},
	async mounted() {
		await this.fetchQuestions()
	},
	methods: {

		async fetchQuestions() {
			const questions = await BrainScanAPIInstance.getQuestions()
			const categories = await BrainScanAPIInstance.getCategories()
			if (categories.error || questions.error) {
				await this.$router.push({
					path: '/'
				});
			} else {
				const transformedData = questions.data.map(item => {
					if (!item.recommendations.length) {
						item.recommendations = recommendationTypes;
					}

					if (!item.subquestion.length) {
						item.subquestion = [
							{
								recommendations: [
									{
										answer_class_id: 5,
										recommendation_title: '',
										title: 'Yes'
									}
								],
								title: '',
							}
						];
					}

					if (!item.subquestion[0].recommendations?.length) {
						item.subquestion[0].recommendations = [
							{
								answer_class_id: 5,
								recommendation_title: '',
								title: 'Yes'
							}
						];
					}
					return item
				});
				this.categories = categories
				this.questions = transformedData
			}
		},

		addQuestion(id = 0) {
			const maxId = this.questions.reduce((max, question) => question.id > max ? question.id : max, 0) + 1
			const itemIndex = this.questions.findIndex(question => question.id === id)
			const newQuestion = mockNewItem(maxId)
			this.questions.splice(itemIndex + 1, 0, newQuestion)
		},

		deleteQuestion(id) {
			this.popupData.popupVisibility = true
			this.popupData.deleteId = id
		},

		handleDeleteQuestionConfirmation(id) {
			this.questions = this.questions.filter(item => item.id !== id)
			this.popupData.popupVisibility = false
		},

		handlePopupClose() {
			this.popupData.popupVisibility = false
			this.popupData.deleteId = null
		},

		async onQuestionsSave() {
			if (this.validateQuestions()) {
				await BrainScanAPIInstance.saveQuestions(this.questions)
				await this.fetchQuestions()
			} else {
				BrainScanAPIInstance.handleError('Please fill all fields')
			}
		},

		onSingleQuestionChangeHandler(data) {
			this.questions.find(item => item.id === data.id).question = data.e
		},

		validateQuestions() {
			/* eslint-disable no-unused-vars */
			let firstLevelValidation = []
			let subQuestionValidation = []
			let recommendationValidation = []
			this.questions.length && this.questions.map(item => {
				/* eslint-disable no-unused-vars */
					const {
						subquestion,
						recommendations,
						...rest
					} = item
				subquestion.forEach(item => {
					subQuestionValidation.push(!(item.title && !item.recommendations[0].recommendation_title.trim()));
				})
				recommendationValidation.push(recommendations.map(item => item.recommendation_title).map(item => item.trim()).every(Boolean))
				firstLevelValidation.push(!(!rest.information || !rest.title));
				/* eslint-disable no-unused-vars */
			})
			subQuestionValidation = subQuestionValidation.every(Boolean)
			firstLevelValidation = firstLevelValidation.every(Boolean)
			recommendationValidation = recommendationValidation.every(Boolean)
			return !!(firstLevelValidation && recommendationValidation && subQuestionValidation)
		},
	}
}
</script>

<style scoped lang="scss">

.questions-wrapper {
	position: relative;
	padding: 40px;

	@media (max-width: 767px) {
		padding: 25px;
	}

}

</style>