<template>
	<div>
		<h2>{{ this.total }} Results</h2>
		<v-client-table
			v-model="userList"
			:columns="columnsOptions"
			:options="options"
		>
			<a slot="delete" slot-scope="users" @click.prevent="popupInit(users.row.id)">Delete</a>
		</v-client-table>
		<Paginate
			v-if="pageCount !== 1"
			v-model="currentPage"
			:page-count="pageCount"
			:container-class="'pagination'"
			:page-class="'page-item'"
			:prev-text="'<'"
			:next-text="'>'"
			:prev-class="'page-item'"
			:next-class="'page-item'"
			:click-handler="paginationHandler"/>
		<ModalView
			v-if="modalView"
			:id="deleteUserId"
			@close="userDelete(deleteUserId)"
			@closeDelete="userDeleteCancel"/>
	</div>
</template>

<script>
import Paginate from 'vuejs-paginate'

import ModalView from './ModalWindow'
import BrainScanAPIInstance from '@/config'
import { tableOptions } from '@/components/userTable/config'

export default {
	name: "usersTable",
	components: {
		ModalView,
		Paginate
	},
	data() {
		return {
			options: tableOptions,
			modalView: false,
			deleteUserId: 0,
			userList: [],
			columnsOptions: ['id', 'email', 'delete'],
			pageCount: 0,
			prefix: '',
			currentPage: 1,
			total: 0
		}
	},
	mounted() {
		this.fetchUsers();
	},
	methods: {
		async fetchUsers() {

			const params = {
				page: this.currentPage
			}
			const response = await BrainScanAPIInstance.getCustomers(params)
			this.userList = response?.data?.results
			this.total = response?.data?.count
			this.pageCount = response?.data?.total_pages || 1
		},

		paginationHandler: function (props) {
			this.currentPage = props;
			this.fetchUsers();
		},

		popupInit: function (id) {
			this.deleteUserId = id;
			this.modalView = true;
		},

		userDeleteCancel: function () {
			this.modalView = false;
		},

		async userDelete(id) {
			await BrainScanAPIInstance.deleteUserById(id)
			this.modalView = false
			this.currentPage = 1
			await this.fetchUsers()
		}
	}
}
</script>

<style lang="scss">
.pagination {
	list-style: none;
	display: flex;
	justify-content: center;
	padding: 0;

	.page-item {
		transition: .4s ease all;
		background-color: #42b883;

		a {
			display: inline-block;
			padding: 6px 10px;
			color: white;
			border-radius: 15px;
		}

		&.active {
			background-color: #35495e;
		}

		&.disabled {
			a {
				color: #35495e;
				cursor: not-allowed;
			}

			&:hover {
				background-color: #eeeeee;
			}

			background-color: #eeeeee;
		}

		margin: 3px;
		border-radius: 15px;

		&:hover {
			a {
				color: white;
			}

			background-color: #35495e;
		}
	}
}
</style>
