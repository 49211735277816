<template>
	<div class="question-wrapper">
		<QuestionSingleAdd
			class="add-button control-button"
			:questionId="question.id"
			@questionAdd="addNewQuestion"
		/>
		<div class="draggable-handler">
			<TheIconComponent
				:pointer="true"
				icon-style="drag-icon"
				:height="22"
				:width="22"
			/>
		</div>
		<div class="expanded-controls">
			{{ question.title }}
			<TheIconComponent
				:pointer="true"
				icon-style="expand"
				@click="toggleQuestionForm"
				class="expand-controller"
				:style="{
					transform: formVisibility ? 'rotate(180deg)' : 'rotate(0deg)',
					transition: '.4s ease all'
				}"
			/>
		</div>
		<QuestionsSingleTable
			:categories="categories"
			:single-question="question"
			:form-visibility="formVisibility"
			@question-form-submit="handleFormSubmit"

		/>
		<QuestionSingleDelete
			:questionId="question.id"
			class="delete-button control-button"
			@questionDelete="deleteQuestion"
		/>
	</div>
</template>

<script>
import TheIconComponent from '@/components/basic/TheIconComponent'
import QuestionSingleAdd from '@/components/Questions/QuestionSingleAdd'
import QuestionSingleDelete from '@/components/Questions/QuestionSingleDelete'
import QuestionsSingleTable from '@/components/Questions/QuestionsSingleTable'

export default {
	props: {
		question: {
			type: Object,
			required: true
		},
		categories: {
			type: Array,
			required: true
		}
	},
	name: "QuestionsSingle",
	data() {
		return {
			formVisibility: false
		}
	},

	components: {
		TheIconComponent,
		QuestionSingleAdd,
		QuestionSingleDelete,
		QuestionsSingleTable
	},

	methods: {
		addNewQuestion(id) {
			this.$emit('onQuestionAdd', id)
		},
		deleteQuestion(id) {
			this.$emit('onQuestionDelete', id)
		},
		toggleQuestionForm() {
			this.formVisibility = !this.formVisibility
		},
		handleFormSubmit(e) {
			this.formVisibility = false
			this.$emit('onSingleQuestionChange', {id: this.question.id, e})
		}
	}
}
</script>

<style scoped lang="scss">

.question-wrapper {
	margin: 0 40px 10px;

	@media (max-width: 767px) {
		margin: 0 25px 10px;
	}
	box-sizing: border-box;
	padding: 10px 5px 10px 60px;
	@media (max-width: 767px) {
		padding: 10px 5px 10px 38px;
	}
	border: 2px solid #eee;
	position: relative;
	border-radius: 10px;

	.expanded-controls {
		display: flex;
		flex-direction: revert;
		justify-content: space-between;
		align-items: center;
	}

	.question-title {
		cursor: pointer;
	}

	.control-button {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	.add-button {
		left: -40px;
	}
	.delete-button {
		right: -40px;
	}

	.expand-controller {
		@media (max-width: 769px) {
			min-width: 30px;
		}
	}

	.draggable-handler {
		position: absolute;
		top: 50%;
		left: 10px;
		transform: translate(0, -50%);
		display: inline-flex;
	}
}

</style>