export const tableOptions = {
	resizableColumns: false,
	perPage: 10,
	perPageValues: [],
	filterable: false,
	highlightMatches: false,
	filterByColumn: false,
	sortable: [],
	skin: '',
	hiddenColumns: ['id'],
	headings: {
		delete: false
	},
	pagination: {
		chunk: 5
	}
};
