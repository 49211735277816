<template>
	<Questions />
</template>

<script>
import Questions from '@/components/Questions/Questions'

export default {
	name: "QuestionsPage",
	components: {
		Questions
	}
}
</script>

<style scoped>

</style>