<template>
	<button
		class="questions-save-button"
		@click.prevent="onQuestionsSave"
	>
		<TheIconComponent
			:pointer="true"
			:icon-style="'save'"
		/>
		Save
	</button>
</template>

<script>
import TheIconComponent from '@/components/basic/TheIconComponent'

export default {
	name: "QuestionsSave",
	components: {
		TheIconComponent
	},
	methods: {
		onQuestionsSave() {
			this.$emit('onQuestionsSave')
		}
	}
}
</script>

<style scoped lang="scss">

.questions-save-button {
	bottom: 30px;
	right: 30px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border-radius: 25px;
	padding: 5px 20px;
	border: 1px solid #eeeeee;
	transition: .4s ease all;
	margin-top: 20px;
	margin-left: auto;

	&:hover {
		background-color: #92A8D1;
	}

	&:focus {
		outline: none;
	}

	& > * {
		padding: 5px;
	}
}
</style>