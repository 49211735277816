<template>
	<div>
		<TheIconComponent
			icon-style="minus"
			@click="questionDelete"
			:pointer="true"
		/>
	</div>
</template>

<script>
import TheIconComponent from '@/components/basic/TheIconComponent'

export default {
	props: ['questionId'],
	name: "QuestionSingleDelete",
	components: {
		TheIconComponent
	},
	methods: {
		questionDelete() {
			this.$emit('questionDelete', this.questionId)
		}
	}
}
</script>