<template>
  <div class="wrapper">
    <form @submit.prevent>
      <VideosSingle
          :new-item-mode="true"
          v-for="singleVideo of videos"
          :key="singleVideo.id"
          :single-video="singleVideo"
          @on-video-delete="deleteVideo"
          :categories="categories"
      />
      <div class="wrapper-video-buttons">
        <router-link class="nav-item" to="/"><button class="video-button">Cancel</button></router-link>
        <router-link class="nav-item" to="/videos/new"><button class="video-button">Add new Video</button></router-link>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
import BrainScanAPIInstance from '@/config'
import VideosSingle from '@/components/Videos/VideosSingle'

export default {
  name: "Videos",
  data() {
    return {
      videos: [],
      categories: []
    }
  },
  components: {
    VideosSingle
  },
  mounted() {
    this.fetchVideosCategories()
    this.fetchVideos()
  },
  methods: {

    async fetchVideos() {
      const videos = await BrainScanAPIInstance.getVideos()
      if (videos && videos.data) {
        this.videos = videos.data
      }
    },

    async fetchVideosCategories() {
      const categories = await BrainScanAPIInstance.getVideosCategories()
      this.categories = categories.data
    },

    async saveVideos() {
      if (this.videos.length && this.videos.every(singleVideo => singleVideo.url)) {

        this.$router.push({ path: '/' })
      } else {
        Vue.notify({
          group: 'error',
          type: 'error',
          title: 'Error occurred',
          text: 'All videos fields are required'
        })
      }
    },

    async deleteVideo(id) {
      await BrainScanAPIInstance.deleteVideoById(id)
      await this.fetchVideos()
    }

  }
}
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  padding: 40px;

  &-add-video {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &-video-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  @media (max-width: 767px) {
    padding: 25px;
  }
  .video-button {
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 25px;
    padding: 15px 25px;
    border: 1px solid #eeeeee;
    transition: .4s ease all;
    margin-top: 20px;
    margin-left: 15px;

    &:hover {
      background-color: #92A8D1;
    }

    &:focus {
      outline: none;
    }
  }
}
</style>