<template>
  <div class="categories-wrapper">
    <SingleQuestionCategory
        :key="singleCategory.id"
        v-for="singleCategory of categories"
        :single-question-category="singleCategory"
    />
    <div class="categories-wrapper-bottom">
      <button class="save-button" @click.prevent="saveQuestionCategories">Save</button>
    </div>
  </div>
</template>

<script>
import BrainScanAPIInstance from '@/config'

import SingleQuestionCategory from './SingleQuestionCategory'


export default {
  name: "Content",
  data() {
    return {
      categories: []
    }
  },
  components: {
    SingleQuestionCategory
  },
  mounted() {
    this.getQuestionCategories()
  },
  methods: {
    async getQuestionCategories() {
      const response = await BrainScanAPIInstance.getQuestionsCategories()
      this.categories = response.data
    },

    transformMarkupToUnderline(string) {
      return string.split('~~')
          .map((item, index) => index % 2 ? `<u>${item}</u>` : item)
          .join('')
    },

    async saveQuestionCategories() {
      await BrainScanAPIInstance.saveQuestionCategories(this.categories)
      await this.getQuestionCategories()
    }
  }
}
</script>

<style scoped lang="scss">

.categories-wrapper {

  &-bottom {
    padding: 0 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .save-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 25px;
    padding: 15px 25px;
    border: 1px solid #eeeeee;
    transition: 0.4s ease all;
  }
}

</style>