<template>
  <div class="single-video-wrapper">
    <div class="left-wrapper">
      <div class="input-wrapper">
        <input
            maxlength="200"
            minlength="5"
            required
            :id="`video-title_${singleVideo.id}`"
            type="text"
            v-model="singleVideo.title"
            placeholder="Enter Title"
            :disabled="newItemMode"
            :class="{'not-editable': newItemMode}"
        >
      </div>
      <div class="input-wrapper input-wrapper-video">
        <label :for="`video-url_${singleVideo.id}`" class="video-holder-label" :class="{'not-editable': newItemMode}">
          {{ labelField }}
          <input
              accept="video/mp4,video/x-m4v,video/3gp,video/ogv,video/avi"
              :id="`video-url_${singleVideo.id}`"
              @change="onVideoChange"
              class="video-holder"
              type="file"
              :disabled="newItemMode"
          >
        </label>
        <select
            :class="{'not-editable': newItemMode}"
            required
            v-model="singleVideo.type"
            :disabled="newItemMode"
        >
          <option v-for="singleCategory of categories" :key="singleCategory.id" :value="singleCategory.id">{{ singleCategory.label }}</option>
        </select>
      </div>
    </div>
    <div class="right-wrapper">
      <TheIconComponent
          v-if="newItemMode"
          icon-style="minus"
          @click="deleteVideo"
          :pointer="true"
      />
    </div>
  </div>
</template>

<script>

import TheIconComponent from '@/components/basic/TheIconComponent'

export default {
  props: {
    singleVideo: {
      type: Object,
      required: false
    },
    categories: {
      type: Array,
      required: true
    },
    newItemMode: {
      type: Boolean,
      required: true
    }
  },
  name: "VideosSingle",
  components: {
    TheIconComponent
  },
  data() {
    return {
      labelText: 'Please Select Video'
    }
  },
  computed: {
    labelField: function () {
      if (this.singleVideo.url && typeof this.singleVideo.url === 'string') {
        return `${this.singleVideo.url.split('videos/')[1].substr(0, 25)}...`
      } else if (typeof this.singleVideo.url === 'object') {
        return `${this.labelText.substr(0, 25)}...`
      }
      return this.labelText
    }
  },
  methods: {
    deleteVideo() {
      this.$emit('on-video-delete', this.singleVideo.id)
    },
    onVideoChange(event) {
      if (event.target.files[0]) {
        this.singleVideo.url = event.target.files[0]
        this.labelText = event.target.files[0].name
      } else {
        this.labelText = 'Please Select Video'
      }
    }
  }
}
</script>

<style scoped lang="scss">


.video-holder-label {
  width: 250px;
  max-width: 250px;
  box-sizing: border-box;
  display: block;
  padding: 10px;
  background-color: #eee;
  border: none;
  color: #000;
  border-radius: 25px;
  margin-right: 10px;
}

.single-video-wrapper {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .left-wrapper {
    flex: 1
  }

  .right-wrapper {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .input-wrapper-video {
    display: flex;
  }

  input {
    width: 100%;
    margin: 0 auto 10px;

    &.video-holder {
      width: auto;
      margin: 0 10px 0 0;
      display: none;
    }

    &[type="submit"] {
      max-width: 120px;
    }
  }

  input,
  select {
    min-width: 250px;
    box-sizing: border-box;
    display: block;
    padding: 10px;
    background-color: #eee;
    border: none;
    color: black;
    border-radius: 25px;
  }
}

.not-editable {
  color: #9a9a9a !important;
  cursor: not-allowed;
}

</style>