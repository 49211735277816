import Vue from 'vue'
import Vuex from 'vuex'
import Cookie from 'js-cookie'
import VuexPersist from 'vuex-persist'

import actions from './actions'
import mutations from './mutations'

const vuexPersist = new VuexPersist({
    key: 'brainscan-app',
    storage: window.localStorage
});

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: {},
        isAuth: Cookie.get('authToken'),
		token: Cookie.get('authToken')
    },
    mutations: mutations,
    actions: actions,
    plugins: [vuexPersist.plugin]
})
